/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "../config.js";
import has from "../core/has.js";
import { isAborted as r } from "../core/promiseUtils.js";
import { getOrigin as o, hasSameOrigin as t, getAppUrl as n, urlToObject as s } from "../core/urlUtils.js";
function i(e, o, t = !1, n) {
  return new Promise((s, i) => {
    if (r(n)) return void i(c());
    let a = () => {
        l(), i(new Error(`Unable to load ${o}`));
      },
      u = () => {
        const r = e;
        l(), s(r);
      },
      m = () => {
        if (!e) return;
        const r = e;
        l(), r.src = "", i(c());
      };
    const l = () => {
      has("esri-image-decode") || (e.removeEventListener("error", a), e.removeEventListener("load", u)), a = null, u = null, e = null, null != n && n.removeEventListener("abort", m), m = null, t && URL.revokeObjectURL(o);
    };
    null != n && n.addEventListener("abort", m), has("esri-image-decode") ? e.decode().then(u, a) : (e.addEventListener("error", a), e.addEventListener("load", u));
  });
}
function c() {
  try {
    return new DOMException("Aborted", "AbortError");
  } catch {
    const e = new Error();
    return e.name = "AbortError", e;
  }
}
const a = "Timeout exceeded";
function u() {
  return new Error(a);
}
function m(e) {
  return "object" == typeof e && !!e && "message" in e && e.message === a;
}
function l(r) {
  e.request.crossOriginNoCorsDomains || (e.request.crossOriginNoCorsDomains = {});
  const t = e.request.crossOriginNoCorsDomains;
  for (let e of r) e = e.toLowerCase(), /^https?:\/\//.test(e) ? t[o(e) ?? ""] = 0 : (t[o("http://" + e) ?? ""] = 0, t[o("https://" + e) ?? ""] = 0);
}
function d(r) {
  const s = e.request.crossOriginNoCorsDomains;
  if (s) {
    let e = o(r);
    if (e) return e = e.toLowerCase(), !t(e, n()) && s[e] < Date.now() - 36e5;
  }
  return !1;
}
async function f(r) {
  const t = e.request.crossOriginNoCorsDomains,
    n = o(r);
  t && n && (t[n.toLowerCase()] = Date.now());
  const i = s(r);
  r = i.path, "json" === i.query?.f && (r += "?f=json");
  try {
    await fetch(r, {
      mode: "no-cors",
      credentials: "include"
    });
  } catch {}
}
export { u as createTimeoutError, d as isNoCorsRequestRequired, m as isTimeoutError, i as loadImageAsync, l as registerNoCorsDomains, f as sendNoCorsRequest };