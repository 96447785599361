/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import t from "../config.js";
import { isSome as n } from "./arrayUtils.js";
import e from "./Error.js";
import { isSerializable as r } from "./JSONSupport.js";
import o from "./Logger.js";
import { parseKnownArcGISOnlineDomain as s } from "../portal/support/urlUtils.js";
import { base64ToArrayBuffer as i, arrayBufferToBase64 as u } from "../support/base64Utils.js";
const l = () => o.getLogger("esri.core.urlUtils"),
  c = t.request,
  f = "esri/config: esriConfig.request.proxyUrl is not set.",
  a = /^\s*[a-z][a-z0-9-+.]*:(?![0-9])/i,
  p = /^\s*http:/i,
  h = /^\s*https:/i,
  d = /^\s*file:/i,
  m = /:\d+$/,
  g = /^https?:\/\/[^/]+\.arcgis.com\/sharing(\/|$)/i,
  y = new RegExp("^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?$"),
  $ = new RegExp("^((([^[:]+):)?([^@]+)@)?(\\[([^\\]]+)\\]|([^[:]*))(:([0-9]+))?$");
class x {
  constructor(t = "") {
    this.uri = t, this.scheme = null, this.authority = null, this.path = null, this.query = null, this.fragment = null, this.user = null, this.password = null, this.host = null, this.port = null;
    let n = this.uri.match(y);
    this.scheme = n[2] || (n[1] ? "" : null), this.authority = n[4] || (n[3] ? "" : null), this.path = n[5], this.query = n[7] || (n[6] ? "" : null), this.fragment = n[9] || (n[8] ? "" : null), null != this.authority && (n = this.authority.match($), this.user = n[3] || null, this.password = n[4] || null, this.host = n[6] || n[7], this.port = n[9] || null);
  }
  toString() {
    return this.uri;
  }
}
const w = {},
  O = new x(t.applicationUrl);
let U = O;
const R = j();
let b = R;
const C = () => U,
  q = () => b;
function j() {
  const t = U.path,
    n = t.substring(0, t.lastIndexOf(t.split("/")[t.split("/").length - 1]));
  return `${`${U.scheme}://${U.host}${null != U.port ? `:${U.port}` : ""}`}${n}`;
}
const L = {
  setAppUrl: t => U = t,
  setAppBaseUrl: t => b = t,
  restoreUrls: () => {
    U = O, b = R;
  }
};
function I(t) {
  if (!t) return null;
  const n = {
      path: null,
      query: null
    },
    e = new x(t),
    r = t.indexOf("?");
  return null === e.query ? n.path = t : (n.path = t.substring(0, r), n.query = A(e.query)), e.fragment && (n.hash = e.fragment, null === e.query && (n.path = n.path.substring(0, n.path.length - (e.fragment.length + 1)))), n;
}
function A(t) {
  const n = t.split("&"),
    e = {};
  for (const r of n) {
    if (!r) continue;
    const t = r.indexOf("=");
    let n, o;
    t < 0 ? (n = decodeURIComponent(r), o = "") : (n = decodeURIComponent(r.slice(0, t)), o = decodeURIComponent(r.slice(t + 1)));
    let s = e[n];
    "string" == typeof s && (s = e[n] = [s]), Array.isArray(s) ? s.push(o) : e[n] = o;
  }
  return e;
}
function v(t, n) {
  return t ? n && "function" == typeof n ? Object.keys(t).map(e => encodeURIComponent(e) + "=" + encodeURIComponent(n(e, t[e]))).join("&") : Object.keys(t).map(e => {
    const o = t[e];
    if (null == o) return "";
    const s = encodeURIComponent(e) + "=",
      i = n?.[e];
    return i ? s + encodeURIComponent(i(o)) : Array.isArray(o) ? o.map(t => r(t) ? s + encodeURIComponent(JSON.stringify(t)) : s + encodeURIComponent(t)).join("&") : r(o) ? s + encodeURIComponent(JSON.stringify(o)) : s + encodeURIComponent(o);
  }).filter(t => t).join("&") : "";
}
function P(t = !1) {
  let n,
    r = c.proxyUrl;
  if ("string" == typeof t) {
    n = dt(t);
    const e = T(t);
    e && (r = e.proxyUrl);
  } else n = !!t;
  if (!r) throw l().warn(f), new e("urlUtils:proxy-not-set", f);
  n && wt() && (r = $t(r));
  return I(r);
}
function S(t) {
  const n = T(t);
  let e, r;
  if (n) {
    const t = E(n.proxyUrl);
    e = t.path, r = t.query ? A(t.query) : null;
  }
  if (e) {
    const n = I(t);
    t = e + "?" + n.path;
    const o = v({
      ...r,
      ...n.query
    });
    o && (t = `${t}?${o}`);
  }
  return t;
}
const k = {
  path: "",
  query: ""
};
function E(t) {
  const n = t.indexOf("?");
  return -1 !== n ? (k.path = t.slice(0, n), k.query = t.slice(n + 1)) : (k.path = t, k.query = null), k;
}
function B(t) {
  return t = (t = Ot(t = Ct(t = E(t).path), !0)).toLowerCase();
}
function H(t) {
  const n = {
      proxyUrl: t.proxyUrl,
      urlPrefix: B(t.urlPrefix)
    },
    e = c.proxyRules,
    r = n.urlPrefix;
  let o = e.length;
  for (let s = 0; s < e.length; s++) {
    const t = e[s].urlPrefix;
    if (0 === r.indexOf(t)) {
      if (r.length === t.length) return -1;
      o = s;
      break;
    }
    0 === t.indexOf(r) && (o = s + 1);
  }
  return e.splice(o, 0, n), o;
}
function T(t) {
  const n = c.proxyRules,
    e = B(t);
  for (let r = 0; r < n.length; r++) if (0 === e.indexOf(n[r].urlPrefix)) return n[r];
}
function W(t, n) {
  if (!t || !n) return !1;
  t = z(t), n = z(n);
  const e = s(t),
    r = s(n);
  return null != e && null != r ? e.portalHostname === r.portalHostname : null == e && null == r && F(t, n, !0);
}
function J(t, n) {
  if (!t || !n) return !1;
  t = z(t), n = z(n);
  const e = s(t),
    r = s(n);
  return null != e && null != r && e.portalHostname === r.portalHostname;
}
function N(t, n) {
  return t = z(t), n = z(n), Ot(t) === Ot(n);
}
function z(t) {
  const n = (t = K(t)).indexOf("/sharing");
  return n > 0 ? t.substring(0, n) : t.replace(/\/+$/, "");
}
function D(t, n = c.interceptors) {
  const e = n => null == n || n instanceof RegExp && n.test(t) || "string" == typeof n && t.startsWith(n);
  if (n) for (const r of n) if (Array.isArray(r.urls)) {
    if (r.urls.some(e)) return r;
  } else if (e(r.urls)) return r;
  return null;
}
function F(t, n, e = !1) {
  if (!t || !n) return !1;
  const r = vt(t),
    o = vt(n);
  return !(!e && r.scheme !== o.scheme) && null != r.host && null != o.host && r.host.toLowerCase() === o.host.toLowerCase() && r.port === o.port;
}
function M(t) {
  if ("string" == typeof t) {
    if (!Y(t)) return !0;
    t = vt(t);
  }
  if (F(t, U)) return !0;
  const n = c.trustedServers || [];
  for (let e = 0; e < n.length; e++) {
    const r = Q(n[e]);
    for (let n = 0; n < r.length; n++) if (F(t, r[n])) return !0;
  }
  return !1;
}
function Q(t) {
  return w[t] || (ht(t) || pt(t) ? w[t] = [new x(_(t))] : w[t] = [new x(`http://${t}`), new x(`https://${t}`)]), w[t];
}
function _(t, n = b, e) {
  return pt(t) ? e?.preserveProtocolRelative ? t : "http" === U.scheme && U.authority === X(t).slice(2) ? `http:${t}` : `https:${t}` : ht(t) ? t : V("/" === t[0] ? Ut(n) : n, t);
}
function G(t, n = b, e) {
  if (null == t || !Y(t)) return t;
  const r = K(t),
    o = r.toLowerCase(),
    s = K(n).toLowerCase().replace(/\/+$/, ""),
    i = e ? K(e).toLowerCase().replace(/\/+$/, "") : null;
  if (i && 0 !== s.indexOf(i)) return t;
  const u = (t, n, e) => -1 === (e = t.indexOf(n, e)) ? t.length : e;
  let l = u(o, "/", o.indexOf("//") + 2),
    c = -1;
  for (; o.slice(0, l + 1) === s.slice(0, l) + "/" && (c = l + 1, l !== o.length);) l = u(o, "/", l + 1);
  if (-1 === c) return t;
  if (i && c < i.length) return t;
  t = r.slice(c);
  const f = s.slice(c - 1).replaceAll(/[^/]+/g, "").length;
  if (f > 0) for (let a = 0; a < f; a++) t = `../${t}`;else t = `./${t}`;
  return t;
}
function K(t) {
  return t = It(t = Lt(t = jt(t = _(t = t.trim()))));
}
function V(...t) {
  const e = t.filter(n);
  if (!e?.length) return;
  const r = [];
  if (Y(e[0])) {
    const t = e[0],
      n = t.indexOf("//");
    -1 !== n && (r.push(t.slice(0, n + 1)), gt(e[0]) && (r[0] += "/"), e[0] = t.slice(n + 2));
  } else "/" === e[0][0] && r.push("");
  const o = e.reduce((t, n) => n ? t.concat(n.split("/")) : t, []);
  for (let n = 0; n < o.length; n++) {
    const t = o[n];
    ".." === t && r.length > 0 && ".." !== r[r.length - 1] ? r.pop() : (!t && n === o.length - 1 || t && ("." !== t || 0 === r.length)) && r.push(t);
  }
  return r.join("/");
}
function X(t, n = !1) {
  if (null == t || Z(t) || tt(t)) return null;
  let e = t.indexOf("://");
  if (-1 === e && pt(t)) e = 2;else {
    if (-1 === e) return null;
    e += 3;
  }
  const r = t.indexOf("/", e);
  return -1 !== r && (t = t.slice(0, r)), n && (t = Ot(t, !0)), t;
}
function Y(t) {
  return pt(t) || ht(t);
}
function Z(t) {
  return null != t && "blob:" === t.slice(0, 5);
}
function tt(t) {
  return null != t && "data:" === t.slice(0, 5);
}
function nt(t) {
  const n = ot(t);
  return n?.isBase64 ? i(n.data) : null;
}
function et(t) {
  return u(t).replaceAll("+", "-").replaceAll("/", "_").replace(/=+$/, "");
}
const rt = /^data:(.*?)(;base64)?,(.*)$/;
function ot(t) {
  const n = t.match(rt);
  if (!n) return null;
  const [, e, r, o] = n;
  return {
    mediaType: e,
    isBase64: !!r,
    data: o
  };
}
function st(t) {
  return t.isBase64 ? `data:${t.mediaType};base64,${t.data}` : `data:${t.mediaType},${t.data}`;
}
async function it(t) {
  return (await fetch(t)).blob();
}
function ut(t) {
  const n = nt(t);
  if (!n) return null;
  const e = ot(t);
  return new Blob([n], {
    type: e.mediaType
  });
}
function lt(t, n) {
  ft(t, n);
}
function ct(t, n) {
  at(t, n);
}
function ft(t, n) {
  const e = ut(t);
  return !!e && at(e, n);
}
function at(t, n) {
  if (!t) return !1;
  const e = document.createElement("a");
  if (!("download" in e)) return !1;
  const r = URL.createObjectURL(t);
  return e.download = n, e.href = r, e.style.display = "none", document.body.appendChild(e), e.click(), document.body.removeChild(e), URL.revokeObjectURL(r), !0;
}
function pt(t) {
  return null != t && "/" === t[0] && "/" === t[1];
}
function ht(t) {
  return null != t && a.test(t);
}
function dt(t) {
  return null != t && h.test(t) || "https" === U.scheme && pt(t);
}
function mt(t) {
  return null != t && p.test(t) || "http" === U.scheme && pt(t);
}
function gt(t) {
  return null != t && d.test(t);
}
function yt(t) {
  return pt(t) ? `http:${t}` : t.replace(h, "http:");
}
function $t(t) {
  return pt(t) ? `https:${t}` : t.replace(p, "https:");
}
function xt() {
  return "http" === U.scheme;
}
function wt() {
  return "https" === U.scheme;
}
function Ot(t, n = !1) {
  return pt(t) ? t.slice(2) : (t = t.replace(a, ""), n && t.length > 1 && "/" === t[0] && "/" === t[1] && (t = t.slice(2)), t);
}
function Ut(t) {
  const n = t.indexOf("//"),
    e = t.indexOf("/", n + 2);
  return -1 === e ? t : t.slice(0, e);
}
function Rt(t) {
  let n = 0;
  if (Y(t)) {
    const e = t.indexOf("//");
    -1 !== e && (n = e + 2);
  }
  const e = t.lastIndexOf("/");
  return e < n ? t : t.slice(0, e + 1);
}
function bt(t, n) {
  if (!t) return "";
  const e = I(t).path.replace(/\/+$/, ""),
    r = e.substring(e.lastIndexOf("/") + 1);
  if (!n?.length) return r;
  const o = new RegExp(`\\.(${n.join("|")})$`, "i");
  return r.replace(o, "");
}
function Ct(t) {
  return t.endsWith("/") ? t : `${t}/`;
}
function qt(t) {
  return t.replace(/\/+$/, "");
}
function jt(t) {
  if (/^https?:\/\//i.test(t)) {
    const n = E(t);
    t = (t = n.path.replaceAll(/\/{2,}/g, "/")).replace("/", "//"), n.query && (t += `?${n.query}`);
  }
  return t;
}
function Lt(t) {
  return t.replace(/^(https?:\/\/)(arcgis\.com)/i, "$1www.$2");
}
function It(t) {
  const n = c.httpsDomains;
  if (!mt(t)) return t;
  const e = t.indexOf("/", 7);
  let r;
  if (r = -1 === e ? t : t.slice(0, e), r = r.toLowerCase().slice(7), m.test(r)) {
    if (!r.endsWith(":80")) return t;
    r = r.slice(0, -3), t = t.replace(":80", "");
  }
  return xt() && r === U.authority && !g.test(t) || (wt() && r === U.authority || n && n.some(t => r === t || r.endsWith(`.${t}`)) || wt() && !T(t)) && (t = $t(t)), t;
}
function At(t, n, e) {
  if (!(n && e && t && Y(t))) return t;
  const r = t.indexOf("//"),
    o = t.indexOf("/", r + 2),
    s = t.indexOf(":", r + 2),
    i = Math.min(o < 0 ? t.length : o, s < 0 ? t.length : s);
  if (t.slice(r + 2, i).toLowerCase() !== n.toLowerCase()) return t;
  return `${t.slice(0, r + 2)}${e}${t.slice(i)}`;
}
function vt(t) {
  return "string" == typeof t ? new x(_(t)) : (t.scheme || (t.scheme = U.scheme), t);
}
function Pt(t) {
  return Nt.test(t);
}
function St(t, n) {
  const e = I(t),
    r = Object.keys(e.query || {});
  return r.length > 0 && n && n.warn("removeQueryParameters()", `Url query parameters are not supported, the following parameters have been removed: ${r.join(", ")}.`), e.path;
}
function kt(t, n, e) {
  const r = I(t),
    o = r.query || {};
  return o[n] = String(e), `${r.path}?${v(o)}`;
}
function Et(t, n) {
  if (!n) return t;
  const e = I(t),
    r = e.query || {};
  for (const [s, i] of Object.entries(n)) null != i && (r[s] = i);
  const o = v(r);
  return o ? `${e.path}?${o}` : e.path;
}
function Bt(t, n) {
  const {
    path: e,
    query: r
  } = I(t);
  if (!r) return t;
  delete r[n];
  const o = v(r);
  return o ? `${e}?${o}` : e;
}
function Ht(t) {
  if (null == t) return null;
  const n = t.match(Jt);
  return n ? n[2] : null;
}
function Tt(t) {
  if (null == t) return null;
  const n = t.match(Jt);
  return n ? {
    path: n[1],
    extension: n[2]
  } : {
    path: t,
    extension: null
  };
}
async function Wt(t) {
  if ("string" == typeof t) {
    return ot(t) ?? {
      data: t
    };
  }
  return new Promise((n, e) => {
    const r = new FileReader();
    r.readAsDataURL(t), r.onload = () => n(ot(r.result)), r.onerror = t => e(t);
  });
}
const Jt = /([^.]*)\.([^/]*)$/,
  Nt = /(^data:image\/svg|\.svg$)/i;
export { x as Url, S as addProxy, H as addProxyRule, kt as addQueryParameter, Et as addQueryParameters, et as base64UrlEncode, it as blobUrlToBlob, At as changeDomain, ot as dataComponents, nt as dataToArrayBuffer, ut as dataToBlob, ct as downloadBlobAsFile, lt as downloadDataAsFile, Ct as ensureTrailingSlash, q as getAppBaseUrl, C as getAppUrl, bt as getFilename, D as getInterceptor, X as getOrigin, Ht as getPathExtension, T as getProxyRule, P as getProxyUrl, ht as hasProtocol, J as hasSameCanonicalArcGISOnlinePortal, W as hasSameCanonicalPortal, F as hasSameOrigin, N as hasSamePortal, Y as isAbsolute, wt as isAppHTTPS, Z as isBlobProtocol, tt as isDataProtocol, dt as isHTTPSProtocol, pt as isProtocolRelative, Pt as isSVG, M as isTrustedServer, V as join, _ as makeAbsolute, st as makeData, G as makeRelative, K as normalize, v as objectToQuery, Wt as parseData, A as queryToObject, Rt as removeFile, Bt as removeQueryParameter, St as removeQueryParameters, qt as removeTrailingSlash, Tt as splitPathExtension, L as test, yt as toHTTP, $t as toHTTPS, w as trustedServersUrlCache, I as urlToObject };