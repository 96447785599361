/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import has from "./core/has.js";
import { addQueryParameter as o } from "./core/urlUtils.js";
import { buildDate as s, commitHash as e } from "./support/revision.js";
Symbol.dispose ??= Symbol("Symbol.dispose"), Symbol.asyncDispose ??= Symbol("Symbol.asyncDispose");
const r = "4.30";
let t,
  i = r;
i = "4.30.9";
function n(o) {
  t = o;
}
function m(s) {
  const e = t?.findCredential(s);
  return e?.token ? o(s, "token", e.token) : s;
}
has("host-webworker");
export { m as addTokenParameter, s as buildDate, i as fullVersion, t as id, e as revision, n as setId, r as version };