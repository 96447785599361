/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function t(t) {
  const n = atob(t),
    r = new Uint8Array(n.length);
  for (let e = 0; e < n.length; e++) r[e] = n.charCodeAt(e);
  return r.buffer;
}
function n(t) {
  const n = new Uint8Array(t);
  let r = "";
  for (let e = 0; e < n.length; e++) r += String.fromCharCode(n[e]);
  return btoa(r);
}
export { n as arrayBufferToBase64, t as base64ToArrayBuffer };