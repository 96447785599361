/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "./config.js";
import { id as t } from "./kernel.js";
import r from "./core/Error.js";
import has from "./core/has.js";
import { clone as s } from "./core/lang.js";
import { onAbort as o, isAbortError as a, createAbortError as n, isAborted as i } from "./core/promiseUtils.js";
import { queryToObject as l, isDataProtocol as u, isBlobProtocol as c, normalize as d, getInterceptor as p, isTrustedServer as h, getOrigin as m, toHTTPS as f, objectToQuery as y, getProxyRule as w, getProxyUrl as g, addQueryParameters as b, hasSameOrigin as q, getAppUrl as S, addProxyRule as T } from "./core/urlUtils.js";
import { isSecureProxyService as k } from "./portal/support/urlUtils.js";
import { supportsApiKey as O } from "./support/apiKeyUtils.js";
import { registerNoCorsDomains as v, isNoCorsRequestRequired as C, sendNoCorsRequest as x, createTimeoutError as L, loadImageAsync as E } from "./support/requestUtils.js";
async function U(e, t) {
  e instanceof URL && (e = e.toString()), t?.query instanceof URLSearchParams && (t.query = l(t.query.toString().replaceAll("+", " ")));
  const r = u(e),
    s = c(e);
  s || r || (e = d(e));
  const a = {
      url: e,
      requestOptions: {
        ...t
      }
    },
    n = e => ({
      data: e,
      getAllHeaders: H,
      getHeader: H,
      httpStatus: 200,
      requestOptions: a.requestOptions,
      url: a.url
    }),
    i = p(e, A.internalInterceptors);
  if (i) {
    const e = await G(i, a);
    if (null != e) return n(e);
  }
  let h = p(e);
  if (h) {
    const e = await G(h, a);
    if (null != e) return n(e);
    h.after || h.error || (h = null);
  }
  if (e = a.url, "image" === (t = a.requestOptions).responseType && (has("host-webworker") || has("host-node"))) throw N("request:invalid-parameters", new Error("responseType 'image' is not supported in Web Workers or Node environment"), a);
  if ("head" === t.method) {
    if (t.body) throw N("request:invalid-parameters", new Error("body parameter cannot be set when method is 'head'"), a);
    if (r || s) throw N("request:invalid-parameters", new Error("data and blob URLs are not supported for method 'head'"), a);
  }
  if (await B(), j) return j.execute(e, t);
  const m = new AbortController(),
    f = o(t, () => m.abort()),
    y = {
      controller: m,
      credential: void 0,
      credentialToken: void 0,
      fetchOptions: void 0,
      hasToken: !1,
      interceptor: h,
      params: a,
      redoRequest: !1,
      useIdentity: A.useIdentity,
      useProxy: !1,
      useSSL: !1,
      withCredentials: !1
    },
    w = await Q(y).finally(() => f?.remove());
  return h?.after?.(w), w;
}
let j;
const A = e.request,
  P = ("FormData" in globalThis),
  _ = new Set([499, 498, 403, 401]),
  D = new Set(["COM_0056", "COM_0057", "SB_0008"]),
  F = [/\/arcgis\/tokens/i, /\/sharing(\/rest)?\/generatetoken/i, /\/rest\/info/i],
  H = () => null,
  I = Symbol();
function R(e) {
  const t = m(e);
  t && !U._corsServers.includes(t) && U._corsServers.push(t);
}
function M(e) {
  const t = m(e);
  return !t || t.endsWith(".arcgis.com") || U._corsServers.includes(t) || h(t);
}
function N(e, t, o, i) {
  let l = "Error";
  const u = {
    url: o.url,
    requestOptions: o.requestOptions,
    getAllHeaders: H,
    getHeader: H,
    ssl: !1
  };
  if (t instanceof r) return t.details ? (t.details = s(t.details), t.details.url = o.url, t.details.requestOptions = o.requestOptions) : t.details = u, t;
  if (t) {
    const e = i && (() => Array.from(i.headers)),
      r = i && (e => i.headers.get(e)),
      s = i?.status,
      o = t.message;
    o && (l = o), e && r && (u.getAllHeaders = e, u.getHeader = r), u.httpStatus = (null != t.httpCode ? t.httpCode : t.code) || s || 0, u.subCode = t.subcode, u.messageCode = t.messageCode, "string" == typeof t.details ? u.messages = [t.details] : u.messages = t.details, u.raw = I in t ? t[I] : t;
  }
  return a(t) ? n() : new r(e, l, u);
}
async function B() {
  has("host-webworker") && !j && (j = await import("./core/workers/request.js"));
}
async function $() {
  t || (await import("./identity/IdentityManager.js"));
}
async function z(r) {
  const s = r.params.url,
    o = r.params.requestOptions,
    a = r.controller.signal,
    n = o.body;
  let l = null,
    u = null;
  if (P && "HTMLFormElement" in globalThis && (n instanceof FormData ? l = n : n instanceof HTMLFormElement && (l = new FormData(n))), "string" == typeof n && (u = n), r.fetchOptions = {
    cache: o.cacheBust ? "no-cache" : "default",
    credentials: "same-origin",
    headers: o.headers || {},
    method: "head" === o.method ? "HEAD" : "GET",
    mode: "cors",
    priority: A.priority,
    redirect: "follow",
    signal: a
  }, (l || u) && (r.fetchOptions.body = l || u), "anonymous" === o.authMode && (r.useIdentity = !1), r.hasToken = !!(/token=/i.test(s) || o.query?.token || l?.get("token")), !r.hasToken && e.apiKey && O(s) && (o.query || (o.query = {}), o.query.token = e.apiKey, r.hasToken = !0), r.useIdentity && !r.hasToken && !r.credentialToken && !K(s) && !i(a)) {
    let e;
    "immediate" === o.authMode ? (await $(), e = await t.getCredential(s, {
      signal: a
    }), r.credential = e) : "no-prompt" === o.authMode ? (await $(), e = await t.getCredential(s, {
      prompt: !1,
      signal: a
    }).catch(() => {}), r.credential = e) : t && (e = t.findCredential(s)), e && (r.credentialToken = e.token, r.useSSL = !!e.ssl);
  }
}
function K(e) {
  return F.some(t => t.test(e));
}
async function W(e) {
  let r = e.params.url;
  const s = e.params.requestOptions,
    o = e.fetchOptions ?? {},
    a = c(r) || u(r),
    i = s.responseType || "json",
    l = a ? 0 : null != s.timeout ? s.timeout : A.timeout;
  let d = !1;
  if (!a) {
    e.useSSL && (r = f(r));
    let a = {
      ...s.query
    };
    e.credentialToken && (a.token = e.credentialToken);
    let n = y(a);
    has("esri-url-encodes-apostrophe") && (n = n.replaceAll("'", "%27"));
    const i = r.length + 1 + n.length;
    let l;
    d = "delete" === s.method || "post" === s.method || "put" === s.method || !!s.body || i > A.maxUrlLength;
    const u = s.useProxy || !!w(r);
    if (u) {
      const e = g(r);
      l = e.path, !d && l.length + 1 + i > A.maxUrlLength && (d = !0), e.query && (a = {
        ...e.query,
        ...a
      });
    }
    if ("HEAD" === o.method && (d || u)) {
      if (d) {
        if (i > A.maxUrlLength) throw N("request:invalid-parameters", new Error("URL exceeds maximum length"), e.params);
        throw N("request:invalid-parameters", new Error("cannot use POST request when method is 'head'"), e.params);
      }
      if (u) throw N("request:invalid-parameters", new Error("cannot use proxy when method is 'head'"), e.params);
    }
    if (d ? (o.method = "delete" === s.method ? "DELETE" : "put" === s.method ? "PUT" : "POST", s.body ? r = b(r, a) : (o.body = y(a), o.headers || (o.headers = {}), o.headers["Content-Type"] = "application/x-www-form-urlencoded")) : r = b(r, a), u && (e.useProxy = !0, r = `${l}?${r}`), a.token && P && o.body instanceof FormData && !k(r) && o.body.set("token", a.token), s.hasOwnProperty("withCredentials")) e.withCredentials = s.withCredentials;else if (!q(r, S())) if (h(r)) e.withCredentials = !0;else if (t) {
      const s = t.findServerInfo(r);
      s?.webTierAuth && (e.withCredentials = !0);
    }
    e.withCredentials && (o.credentials = "include", C(r) && (await x(d ? b(r, a) : r)));
  }
  let p,
    O,
    v = 0,
    E = !1;
  l > 0 && (v = setTimeout(() => {
    E = !0, e.controller.abort();
  }, l));
  try {
    if ("native-request-init" === s.responseType) O = o, O.url = r;else if ("image" !== s.responseType || "default" !== o.cache || "GET" !== o.method || d || J(s.headers) || !a && !e.useProxy && A.proxyUrl && !M(r)) {
      if (U._beforeFetch && (await U._beforeFetch(r, o)), p = await fetch(r, o), U._afterFetch && (await U._afterFetch(p)), e.useProxy || R(r), "native" === s.responseType) O = p;else if ("HEAD" !== o.method) if (p.ok) {
        switch (i) {
          case "array-buffer":
            O = await p.arrayBuffer();
            break;
          case "blob":
          case "image":
            O = await p.blob();
            break;
          default:
            O = await p.text();
        }
        if (v && (clearTimeout(v), v = 0), "json" === i || "xml" === i || "document" === i) if (O) switch (i) {
          case "json":
            O = JSON.parse(O);
            break;
          case "xml":
            O = X(O, "application/xml");
            break;
          case "document":
            O = X(O, "text/html");
        } else O = null;
        if (O) {
          if ("array-buffer" === i || "blob" === i) {
            const e = p.headers.get("Content-Type");
            if (e && /application\/json|text\/plain/i.test(e) && O["blob" === i ? "size" : "byteLength"] <= 750) try {
              const e = await new Response(O).json();
              e.error && (O = e);
            } catch {}
          }
          "image" === i && O instanceof Blob && (O = await Y(URL.createObjectURL(O), e, !0));
        }
      } else {
        O = await p.text();
        try {
          O = JSON.parse(O);
        } catch {}
      }
    } else O = await Y(r, e);
  } catch (j) {
    if ("AbortError" === j.name) {
      if (E) throw L();
      throw n("Request canceled");
    }
    if (!(!p && j instanceof TypeError && A.proxyUrl) || s.body || "delete" === s.method || "head" === s.method || "post" === s.method || "put" === s.method || e.useProxy || M(r)) throw j;
    e.redoRequest = !0, T({
      proxyUrl: A.proxyUrl,
      urlPrefix: m(r) ?? ""
    });
  } finally {
    v && clearTimeout(v);
  }
  return [p, O];
}
async function G(e, t) {
  if (null != e.responseData) return e.responseData;
  if (e.headers && (t.requestOptions.headers = {
    ...t.requestOptions.headers,
    ...e.headers
  }), e.query && (t.requestOptions.query = {
    ...t.requestOptions.query,
    ...e.query
  }), e.before) {
    let o, a;
    try {
      a = await e.before(t);
    } catch (s) {
      o = N("request:interceptor", s, t);
    }
    if ((a instanceof Error || a instanceof r) && (o = N("request:interceptor", a, t)), o) throw e.error && e.error(o), o;
    return a;
  }
}
function J(e) {
  if (e) for (const t of Object.getOwnPropertyNames(e)) if (e[t]) return !0;
  return !1;
}
function X(e, t) {
  let r;
  try {
    r = new DOMParser().parseFromString(e, t);
  } catch {}
  if (!r || r.getElementsByTagName("parsererror").length) throw new SyntaxError("XML Parse error");
  return r;
}
async function Q(e) {
  let r, s;
  await z(e);
  try {
    do {
      [r, s] = await W(e);
    } while (!(await V(e, r, s)));
  } catch (n) {
    const t = N("request:server", n, e.params, r);
    throw t.details.ssl = e.useSSL, e.interceptor?.error && e.interceptor.error(t), t;
  }
  const o = e.params.url;
  if (s && /\/sharing\/rest\/(accounts|portals)\/self/i.test(o)) {
    if (!e.hasToken && !e.credentialToken && s.user?.username && !h(o)) {
      const e = m(o, !0);
      e && A.trustedServers.push(e);
    }
    Array.isArray(s.authorizedCrossOriginNoCorsDomains) && v(s.authorizedCrossOriginNoCorsDomains);
  }
  const a = e.credential;
  if (a && t) {
    const e = t.findServerInfo(a.server);
    let r = e?.owningSystemUrl;
    if (r) {
      r = r.replace(/\/?$/, "/sharing");
      const e = t.findCredential(r, a.userId);
      e && -1 === t._getIdenticalSvcIdx(r, e) && e.resources.unshift(r);
    }
  }
  return {
    data: s,
    getAllHeaders: r ? () => Array.from(r.headers) : H,
    getHeader: r ? e => r.headers.get(e) : H,
    httpStatus: r?.status ?? 200,
    requestOptions: e.params.requestOptions,
    ssl: e.useSSL,
    url: e.params.url
  };
}
async function V(e, r, s) {
  if (e.redoRequest) return e.redoRequest = !1, !1;
  const o = e.params.requestOptions;
  if (!r || "native" === o.responseType || "native-request-init" === o.responseType) return !0;
  let a, n;
  if (s && (s.error && "object" == typeof s.error ? a = s.error : "error" === s.status && Array.isArray(s.messages) && (a = {
    ...s
  }, a[I] = s, a.details = s.messages)), !a && !r.ok) throw a = new Error(`Unable to load ${r.url} status: ${r.status}`), a[I] = s, a;
  let i,
    l = null;
  a && (n = Number(a.code), l = a.hasOwnProperty("subcode") ? Number(a.subcode) : null, i = a.messageCode, i = i?.toUpperCase());
  const u = o.authMode;
  if (403 === n && (4 === l || a.message?.toLowerCase().includes("ssl") && !a.message.toLowerCase().includes("permission"))) {
    if (!e.useSSL) return e.useSSL = !0, !1;
  } else if (!e.hasToken && e.useIdentity && ("no-prompt" !== u || 498 === n) && void 0 !== n && _.has(n) && !K(e.params.url) && (403 !== n || (!i || !D.has(i)) && (null == l || 2 === l && e.credentialToken))) {
    await $();
    try {
      const r = await t.getCredential(e.params.url, {
        error: N("request:server", a, e.params),
        prompt: "no-prompt" !== u,
        signal: e.controller.signal,
        token: e.credentialToken
      });
      return e.credential = r, e.credentialToken = r.token, e.useSSL = e.useSSL || r.ssl, !1;
    } catch (c) {
      if ("no-prompt" === u) return e.credential = void 0, e.credentialToken = void 0, !1;
      a = c;
    }
  }
  if (a) throw a;
  return !0;
}
function Y(e, t, r = !1) {
  const s = t.controller.signal,
    o = new Image();
  return t.withCredentials ? o.crossOrigin = "use-credentials" : o.crossOrigin = "anonymous", o.alt = "", o.fetchPriority = A.priority, o.src = e, E(o, e, r, s);
}
U._corsServers = ["https://server.arcgisonline.com", "https://services.arcgisonline.com"], U._beforeFetch = void 0, U._afterFetch = void 0;
export { U as default };